/* eslint-disable */
import React from 'react'

import SeniorMan from '../images/people/senior-man.png'
import grayImgBackground from '../images/backgrounds/gray-img-background.jpg'
import { useMapi } from '@leshen/gatsby-theme-leshen'
import header from '../components/Header'
import footer from '../components/Footer'
import {
  Accordion,
  Billboard,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import NarrowBanner from '../components/NarrowBanner'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Clearlink Insurance Agency | Insurance Quotes | 833-488-0038',
          description:
            'Get unbiased help finding a Supplemental Health or Medicare Health plan from our team of licensed agents to get the coverage and rate you need.',
          canonical: 'https://clearlinkinsurance.com/faq',
          robots: 'follow,index',
        },
        path: '/faq',
        promoCode: '169448',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Frequently Asked Questions | Clearlink Insurance Agency  | 833-488-0038',
    defaultRobots: 'follow,index',
    main: (
      <>
        <Billboard
          backgroundColor="darker"
          alignImageToBottom
          variant="split"
          className="faq-hero-split"
          image={
            <img src={SeniorMan} alt="woman with hands in pockets smiling" />
          }
          mobileImage={
            <img src={SeniorMan} alt="woman with hands in pockets smiling" />
          }
          mainContent={
            <>
              <Typography variant="h1">
                Insurance Frequently Asked Questions
              </Typography>
            </>
          }
        />

        <div className="gray-img-background">
          <VariableContent
            mainContent={
              <>
                <Typography
                  variant="body"
                  className="text-left-on-small medium"
                >
                  Insurance can be complicated, whether we're discussing
                  Medicare, supplemental health coverage, or ancillary coverage.
                  Our experts at Clearlink Insurance have worked hard to give
                  you clarity about your healthcare concerns. Below are some
                  frequently asked questions pertaining to healthcare insurance.
                  Still have questions? Call us for a more personal experience
                  with one of our certified agents.
                </Typography>
              </>
            }
            alignMainContent="Center"
            style={{
              backgroundImage: `url(${grayImgBackground})`,
            }}
          ></VariableContent>
        </div>

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Insurance FAQs</Typography>
              <Typography variant="h4">Medicare</Typography>
            </>
          }
          className="padding-bottom-40 heading-small-margin-bottom"
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  content: (
                    <Typography>
                      Medicare is a federally funded health insurance program,
                      primarily for people 65 and over. Medicare is also
                      available to younger people with certain diseases and
                      disabilities like end-stage renal disease or amyotrophic
                      lateral sclerosis (ALS or Lou Gehrig's disease). Depending
                      on the Medicare plan, it can cover expenses related to
                      inpatient care, outpatient care, vision, dental, and
                      prescription drugs.
                    </Typography>
                  ),
                  title: 'What is Medicare?',
                },
                {
                  content: (
                    <>
                      <Typography>
                        There are different parts to Medicare, some federally
                        funded and some private.
                      </Typography>
                      <Typography>
                        <strong>Medicare Part A</strong> covers inpatient care,
                        including hospital visits, nursing home care, home
                        health services, and hospice care.
                      </Typography>
                      <Typography>
                        <strong>Medicare Part B</strong> covers outpatient care,
                        including medically necessary and preventative care.
                      </Typography>
                      <Typography>
                        <strong>Medicare Part C</strong> is a health insurance
                        plan that the government requires to cover everything
                        Medicare Parts A and B cover. They’re available through
                        licensed private insurance companies and may include
                        additional benefits such as gym memberships, vision, and
                        dental coverage.
                      </Typography>
                      <Typography>
                        <strong>Medigap (Supplemental Medicare)</strong> helps
                        pay for some costs not covered by Medicare Parts A and
                        B, such as copayments, coinsurance,  and deductibles.
                      </Typography>
                    </>
                  ),
                  title: 'What are the different “parts” of Medicare?',
                },
                {
                  content: (
                    <Typography>
                      A Medicare beneficiary is someone who is entitled to
                      Medicare benefits. Medicare is primarily for people 65 and
                      older, but can also be available to younger people with
                      illnesses like ESRD and ALS.
                    </Typography>
                  ),
                  title: 'What is a Medicare beneficiary?',
                },
                {
                  content: (
                    <>
                      <Typography>
                        Medicare covers a plethora of healthcare services. From
                        inpatient to outpatient care, Medicare ensures your
                        out-of-pocket costs are minimal. You can have some or a
                        lot of coverage, depending on the Medicare part(s) you
                        have. Each part of Medicare covers a different set of
                        services or health care expenses.
                      </Typography>
                      <Typography>
                        <strong>Medicare Part A</strong>
                      </Typography>
                      <Typography>
                        Medicare Part A covers a variety of emergency and
                        long-term care services. These services generally
                        include:
                      </Typography>
                      <List>
                        <ListItem>Nursing homes</ListItem>
                        <ListItem>Hospital procedures and stays</ListItem>
                        <ListItem>Hospice care</ListItem>
                        <ListItem>
                          Home health care that occurs after an inpatient stay
                        </ListItem>
                        <ListItem>
                          Blood transfusions that you may receive at a hospital
                          (above three pints annually)
                        </ListItem>
                      </List>
                      <Typography>
                        <strong>Medicare Part B</strong>
                      </Typography>
                      <Typography>
                        Medicare Part B covers a broad range of preventative and
                        other medical services. Many preventative services will
                        be 100 percent covered while others may be billed on an
                        80/20 coinsurance basis. These may include services such
                        as:
                      </Typography>
                      <List>
                        <ListItem>Doctor visits</ListItem>
                        <ListItem>Screenings and lab tests</ListItem>
                        <ListItem>
                          Counseling, therapy, and mental health
                        </ListItem>
                        <ListItem>Shots and immunizations</ListItem>
                        <ListItem>Ambulance</ListItem>
                        <ListItem>Outpatient care</ListItem>
                        <ListItem>Surgeries</ListItem>
                        <ListItem>X-rays</ListItem>
                        <ListItem>Clinical research studies</ListItem>
                        <ListItem>
                          Limited outpatient prescription drugs
                        </ListItem>
                      </List>
                      <Typography>
                        <strong>Medicare Part C (Medicare Advantage)</strong>
                      </Typography>
                      <Typography>
                        Medicare Advantage, also known as “Medicare Part C,”
                        offers all the same benefits as Medicare Parts A and B
                        (“Original” Medicare) above, but many offer additional
                        insurance for services not covered under Original
                        Medicare. Depending on your coverage area, your
                        Advantage plan may include one or more of the following:
                      </Typography>
                      <List>
                        <ListItem>Vision</ListItem>
                        <ListItem>Dental</ListItem>
                        <ListItem>Hearing aids</ListItem>
                        <ListItem>Health and wellness programs</ListItem>
                        <ListItem>Gym memberships</ListItem>
                        <ListItem>Prescription drug coverage</ListItem>
                      </List>
                      <Typography>
                        <strong>Medicare Part D</strong>
                      </Typography>
                      <Typography>
                        Medicare Part D covers a wide variety of prescription
                        drugs and medications. Each Medicare Part D plan offers
                        a different range of medications (known as a
                        “formulary”) at different rates (known as “tiers”),
                        depending on your coverage area, the cost of
                        medications, and whether the drugs you need are generic
                        or brand name.
                      </Typography>
                      <Typography>
                        <strong>Medicare Supplement (Medigap)</strong>
                      </Typography>
                      <Typography>
                        Medicare Supplement or “Medigap” helps pay for some
                        health care costs not covered by your Original Medicare
                        or Medicare Advantage plan. These may include expenses
                        such as:
                      </Typography>
                      <List>
                        <ListItem>Deductibles</ListItem>
                        <ListItem>Copayments</ListItem>
                        <ListItem>Coinsurance</ListItem>
                        <ListItem>Care that you receive overseas</ListItem>
                      </List>
                      <Typography>
                        Medicare Supplement plans do not cover prescription
                        drugs or medications. Prescription drugs are only
                        covered by Medicare Part D mentioned above and some
                        Medicare Part C plans.
                      </Typography>
                    </>
                  ),
                  title: 'What does Medicare cover?',
                },
                {
                  content: (
                    <Typography>
                      According to the U.S Department of Health and Human
                      Services, “Medicare is federal health insurance for anyone
                      age 65 and older, and some people under 65 with certain
                      disabilities or conditions. Medicaid is a joint federal
                      and state program that gives health coverage to some
                      people with limited income and resources.”
                    </Typography>
                  ),
                  title: 'What’s the difference between Medicare and Medicaid?',
                },
                {
                  content: (
                    <>
                      <Typography>
                        Medicare is a government-run program aimed at aiding
                        individuals who might not be eligible for private health
                        insurance. It provides health insurance benefits to
                        those who are 65 years or older and to those with
                        certain disabilities. These benefits are offered to all
                        eligible applicants, regardless of their income level,
                        and may even provide some coverage at little to no cost.{' '}
                      </Typography>
                      <Typography>
                        On the other hand, private health insurance is operated
                        by commercial entities. This means that individuals have
                        more options when it comes to choosing coverage and
                        pricing. However, before you can enroll in a private
                        insurance plan, the insurance company will evaluate your
                        risk based on factors like your medical history and age.
                        Depending on the results of this evaluation, you may be
                        charged a higher premium or even denied coverage.
                      </Typography>
                    </>
                  ),
                  title:
                    "What's the difference between Medicare and traditional private health insurance?",
                },
                {
                  content: (
                    <Typography>
                      You are eligible for Medicare when you turn 65. Those
                      younger than 65 with certain disabilities like end-stage
                      renal disease or amyotrophic lateral sclerosis (ALS or Lou
                      Gehrig's disease) are also eligible.
                    </Typography>
                  ),
                  title: 'When am I eligible for Medicare?',
                },
                {
                  content: (
                    <>
                      <Typography>
                        There are three primary enrollment periods for Medicare
                        plans: the Initial Enrollment Period, the General
                        Enrollment Period, and the Annual Enrollment Period.
                      </Typography>
                      <Typography>
                        <strong>The Initial Enrollment Period (IEP)</strong>{' '}
                        begins three months before the month you turn 65 and
                        ends three months after the month of your 65th birthday.
                        If you are younger than 65, your Initial Enrollment
                        Period may be triggered by a disability or other
                        conditions.
                      </Typography>
                      <Typography>
                        <strong>The General Enrollment Period (GEP)</strong>{' '}
                        begins every year on January 1 and goes until March 31
                        and it’s for those who didn’t enroll during their IEP.
                        If you wait until the GEP, you might have to pay some
                        late penalties. Coverage for enrolling during the GEP
                        starts July 1st.
                      </Typography>
                      <Typography>
                        <strong>The Annual Enrollment Period (AEP)</strong>{' '}
                        starts October 15th and ends on December 7th. During
                        this enrollment period, you can switch your Medicare
                        plans, add prescription drug coverage or enroll in a
                        Medicare Supplement (Medigap) plan, or cancel any of
                        your existing plans altogether.
                      </Typography>
                      <Typography>
                        <strong>The Special Enrollment Period (SEP)</strong>{' '}
                        doesn’t have a set start or end date, but can be
                        triggered by qualifying life circumstances including but
                        not limited to moving to a new area, living in a nursing
                        home, or qualifying for both Medicare and Medicaid.
                      </Typography>
                    </>
                  ),
                  title: 'When can I enroll in Medicare?',
                },
                {
                  content: (
                    <Typography>
                      Yes, Medicare premiums are tax deductible as a medical
                      expense as long as you itemize your deductions on your tax
                      return. Only medical expenses that exceed 7.5% of your
                      adjusted gross income (AGI) are deductible, so keep that
                      in mind when doing your taxes.
                    </Typography>
                  ),
                  title: 'Are Medicare payments tax deductible?',
                },
                {
                  content: (
                    <>
                      <Typography>
                        Depending on your Medicare type, there are a few
                        scenarios in which Medicare may drop you. The most
                        common ones are if:
                      </Typography>
                      <List>
                        <ListItem>
                          You no longer have a qualifying disability
                        </ListItem>
                        <ListItem>You fail to pay your plan premiums</ListItem>
                        <ListItem>
                          You move outside your plan's coverage area
                        </ListItem>
                        <ListItem>Your plan is discontinued</ListItem>
                      </List>
                    </>
                  ),
                  title: 'Can I be dropped from Medicare?',
                },
                {
                  content: (
                    <Typography>
                      If you're getting Medicare Part A and Medicare Part B, you
                      may automatically enroll if you already get benefits from
                      Social Security (or the Railroad Retirement Board) before
                      you turn 65. If you’re not automatically enrolled, you’ll
                      have to sign up through the Social Security office once
                      you turn 65. If you want to get Medicare Part C, Part D,
                      or Medigap, call us and we can find available Medicare
                      plans in your area.
                    </Typography>
                  ),
                  title: 'How do I enroll in a Medicare plan?',
                },
              ]}
            />
          </Stack>
        </VariableContent>
        <VariableContent
          alignMainContent="center"
          mainContent={<Typography variant="h4">Supplemental</Typography>}
          className="faq-section-padding"
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  content: (
                    <Typography>
                      Supplemental insurance is insurance that covers expenses
                      your health insurance doesn’t. That includes copayments,
                      coinsurances, and deductibles.
                    </Typography>
                  ),
                  title: 'What is supplemental insurance?',
                },
                {
                  content: (
                    <Typography>
                      A supplemental plan can help pay health insurance
                      deductibles or copayments. It can also pay for specific
                      care, such as cancer or critical illness. Supplemental
                      health insurance plans work differently than health
                      insurance plans, and specifics depend on the plan.
                    </Typography>
                  ),
                  title: 'How does supplemental insurance work?',
                },
                {
                  content: (
                    <Typography>
                      Not to be confused with supplemental insurance, ancillary
                      plans are secondary insurances to your health insurance
                      and usually cover dental, vision, and other major expenses
                      in an emergency.
                    </Typography>
                  ),
                  title: 'What are ancillary plans?',
                },
                {
                  content: (
                    <Typography>
                      Accident insurance is precisely what it sounds
                      like—coverage in case of an injury or death due to an
                      accident. This type of policy is great for those who lack
                      adequate health insurance or would suffer financially if a
                      family breadwinner died, treating an accident insurance
                      policy as life insurance.
                    </Typography>
                  ),
                  title: 'What is accident insurance?',
                },
                {
                  content: (
                    <Typography>
                      Critical illness/cancer insurance covers medical expenses
                      caused by life-threatening illnesses such as cancer, a
                      heart attack, or a stroke. While some may believe their
                      health insurance covers that already, they might not get
                      all the coverage needed for the medical expenses that come
                      with these illnesses.
                    </Typography>
                  ),
                  title: 'What is critical illness/cancer insurance?',
                },
                {
                  content: (
                    <Typography>
                      Final expense life insurance, also known as burial or
                      funeral insurance, is a popular policy amongst seniors
                      that covers the final expenses that come with death. It
                      lifts the burden of costs off of your loved ones and
                      covers medical bills as well as any funeral or burial
                      expenses.
                    </Typography>
                  ),
                  title: 'What is final expense life insurance?',
                },
              ]}
            />
          </Stack>
        </VariableContent>
        <VariableContent
          alignMainContent="center"
          mainContent={
            <Typography variant="h4">
              Working with Clearlink Insurance
            </Typography>
          }
          className="faq-section-padding"
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  content: (
                    <Typography>
                      When you work with Clearlink Insurance, you won’t have to
                      scour the internet to find a healthcare plan that works
                      with your lifestyle. We already work with the top
                      insurance providers in the country, which allows us to
                      present to you your best insurance options. Working with
                      an independent insurance agency makes it easy to find what
                      you’re looking for all in one place. You can bundle
                      multiple plans in one place to get all the health coverage
                      you need, from Medicare to supplemental insurance.
                    </Typography>
                  ),
                  title: 'Why work with an independent insurance agency?',
                },
                {
                  content: (
                    <Typography>
                      We work with top insurance providers nationwide,
                      specializing in Medicare, supplemental insurance, and
                      ancillary policies.
                    </Typography>
                  ),
                  title: 'What products can I buy from you?',
                },
                {
                  content: (
                    <Typography>
                      We have more than 70 agents across all 50 states. They’re
                      licensed and have all the insurance answers to help find
                      the right plan for you.
                    </Typography>
                  ),
                  title: 'Where are your agents licensed to sell?',
                },
                {
                  content: (
                    <Typography>
                      We work with top insurance providers, including but not
                      limited to Aetna, Cigna, Regence, and United Healthcare.
                    </Typography>
                  ),
                  title: 'Which insurance providers do you work with?',
                },
                {
                  content: (
                    <Typography>
                      Absolutely! Our agents are licensed for multiple insurance
                      types, including Medicare, supplemental insurance, and
                      property and casualty insurance. Give us a call to get the
                      healthcare plans you need.
                    </Typography>
                  ),
                  title:
                    'Can I buy more than one plan or type of coverage from you?',
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                Call Now to Speak to an Agent {rotatedNumber}
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

import React from 'react'
import styled from '@emotion/styled'

const Banner = styled.div`
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;

  .heading * {
    color: white;
    font-size: 32px;
    margin-bottom: 0;
    text-align: center;
  }

  @media (min-width: 768px) {
    padding: 25px;
    flex-direction: row;
  }
`

const NarrowBanner = ({
  backgroundImage,
  heading,
  leftButton,
  rightButton,
  backgroundColor,
  centeredButton,
}) => {
  return (
    <Banner
      backgroundImage={backgroundImage}
      style={{ backgroundColor: `${backgroundColor}` }}
    >
      {heading && <div className="heading">{heading}</div>}
      {leftButton && leftButton}
      {rightButton && rightButton}
      {centeredButton && centeredButton}
    </Banner>
  )
}

export default NarrowBanner
